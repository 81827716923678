import paymentApi from '../api/payment'
import { PAYMENT_SUCCESS, PAYMENT_FAILURE, SET_PAYMENT } from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    payment: null,
  },
  actions: {
    getPayment({ commit }) {
      return paymentApi
        .getPayment()
        .then(({ data }) => {
          const payment = {}
          for (const key in data[0]) {
            payment[convertSnakeToCamelCase(key)] =
              data[0][key] === null ? '' : data[0][key]
          }
          commit(SET_PAYMENT, payment)
        })
        .catch(function (error) {
          console.log(error)
          commit(PAYMENT_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_PAYMENT](state, data) {
      state.payment = data
    },
    [PAYMENT_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [PAYMENT_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    payment: (state) => state.payment,
  },
}
