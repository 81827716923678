import radioApi from '../api/radio'
import { SET_RADIO, SET_RADIOS, RADIO_SUCCESS, RADIO_FAILURE } from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    radio: null,
    radios: [],
  },
  actions: {
    upsertRadio({ commit }, newRadio) {
      if (newRadio.file) {
        let formData = new FormData()
        for (const key in newRadio) {
          if (key === 'radio') {
            formData.append(key, JSON.stringify(newRadio[key]))
          } else {
            formData.append(key, newRadio[key])
          }
        }
        newRadio = formData
      }
      return radioApi
        .upsertRadio(newRadio)
        .then(({ data }) => {
          const radio = {}
          for (const key in data) {
            radio[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_RADIO, radio)
        })
        .catch(function (error) {
          console.log(error)
          commit(RADIO_FAILURE, error.response.data)
        })
    },
    getRadio({ commit }, idRadio) {
      return radioApi
        .getRadio(idRadio)
        .then(({ data }) => {
          const radio = {}
          for (const key in data) {
            radio[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_RADIO, radio)
        })
        .catch(function (error) {
          console.log(error)
          commit(RADIO_FAILURE, error.response.data)
        })
    },
    getRadios({ commit }, { patientId }) {
      return radioApi
        .getRadios(patientId)
        .then(({ data }) => {
          const radios = data.map((item) => {
            const radio = {}
            for (const key in item) {
              radio[convertSnakeToCamelCase(key)] = item[key]
            }
            return radio
          })
          commit(SET_RADIOS, radios)
        })
        .catch(function (error) {
          console.log(error)
          commit(RADIO_FAILURE, error.response.data)
        })
    },
    deleteRadio({ commit }, radio) {
      return radioApi
        .deleteRadio(radio)
        .then(() => {
          commit(RADIO_SUCCESS)
        })
        .catch(function (error) {
          console.log(error)
          commit(RADIO_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_RADIOS](state, data) {
      state.radios = data
    },
    [SET_RADIO](state, data) {
      state.radio = data
    },
    [RADIO_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [RADIO_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    radios: (state) => state.radios,
    radio: (state) => state.radio,
  },
}
