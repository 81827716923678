import childApi from '../api/child'
import { SET_CHILD, SET_CHILDS, CHILD_SUCCESS, CHILD_FAILURE } from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    child: null,
    childs: [],
  },
  actions: {
    upsertChild({ commit }, newChild) {
      return childApi
        .upsertChild(newChild)
        .then(({ data }) => {
          const child = {}
          for (const key in data) {
            child[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_CHILD, child)
        })
        .catch(function (error) {
          console.log(error)
          commit(CHILD_FAILURE, error.response.data)
        })
    },
    getChild({ commit }, idChild) {
      return childApi
        .getChild(idChild)
        .then(({ data }) => {
          const child = {}
          for (const key in data) {
            child[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_CHILD, child)
        })
        .catch(function (error) {
          console.log(error)
          commit(CHILD_FAILURE, error.response.data)
        })
    },
    getChilds({ commit }, { patientId }) {
      return childApi
        .getChilds(patientId)
        .then(({ data }) => {
          const childs = data.map((item) => {
            const child = {}
            for (const key in item) {
              child[convertSnakeToCamelCase(key)] = item[key]
            }
            return child
          })
          commit(SET_CHILDS, childs)
        })
        .catch(function (error) {
          console.log(error)
          commit(CHILD_FAILURE, error.response.data)
        })
    },
    deleteChild({ commit }, child) {
      return childApi
        .deleteChild(child)
        .then(() => {
          commit(CHILD_SUCCESS)
        })
        .catch(function (error) {
          console.log(error)
          commit(CHILD_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_CHILDS](state, data) {
      state.childs = data
    },
    [SET_CHILD](state, data) {
      state.child = data
    },
    [CHILD_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [CHILD_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    childs: (state) => state.childs,
    child: (state) => state.child,
  },
}
