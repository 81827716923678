import constantApi from '../api/constant'
import {
  SET_CONSTANT,
  SET_CONSTANTS,
  CONSTANT_SUCCESS,
  CONSTANT_FAILURE,
} from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    constant: null,
    constants: [],
  },
  actions: {
    upsertConstant({ commit }, newConstant) {
      return constantApi
        .upsertConstant(newConstant)
        .then(({ data }) => {
          const constant = {}
          for (const key in data) {
            constant[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_CONSTANT, constant)
        })
        .catch(function (error) {
          console.log(error)
          commit(CONSTANT_FAILURE, error.response.data)
        })
    },
    getConstant({ commit }, idConstant) {
      return constantApi
        .getConstant(idConstant)
        .then(({ data }) => {
          const constant = {}
          for (const key in data) {
            constant[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_CONSTANT, constant)
        })
        .catch(function (error) {
          console.log(error)
          commit(CONSTANT_FAILURE, error.response.data)
        })
    },
    getConstants({ commit }, { patientId }) {
      return constantApi
        .getConstants(patientId)
        .then(({ data }) => {
          const constants = data.map((item) => {
            const constant = {}
            for (const key in item) {
              constant[convertSnakeToCamelCase(key)] = item[key]
            }
            return constant
          })
          commit(SET_CONSTANTS, constants)
        })
        .catch(function (error) {
          console.log(error)
          commit(CONSTANT_FAILURE, error.response.data)
        })
    },
    deleteConstant({ commit }, constant) {
      return constantApi
        .deleteConstant(constant)
        .then(() => {
          commit(CONSTANT_SUCCESS)
        })
        .catch(function (error) {
          console.log(error)
          commit(CONSTANT_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_CONSTANTS](state, data) {
      state.constants = data
    },
    [SET_CONSTANT](state, data) {
      state.constant = data
    },
    [CONSTANT_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [CONSTANT_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    constants: (state) => state.constants,
    constant: (state) => state.constant,
  },
}
