import session from './session'

export default {
  login(username, password) {
    return session.post('/api/auth/login/', { username, password })
  },
  logout() {
    return session.post('/api/auth/logout/', {})
  },
  sendPasswordResetEmail(email) {
    return session.post('/api/auth/password/reset/', { email })
  },
  resetPassword(uid, token, password1, password2) {
    const data = {
      uid: uid,
      token: token,
      new_password1: password1,
      new_password2: password2,
    }
    return session.post('/api/auth/password/reset/confirm/', data)
  },
  createAccount(username, password1, password2, email) {
    return session.post('/api/registration/', {
      username,
      password1,
      password2,
      email,
    })
  },
  verifyAccountEmail(key) {
    return session.post('/api/registration/verify-email/', { key })
  },
  getUser() {
    return session.get('api/auth/user/')
  },
  getProfile(all) {
    if (all) {
      return session.get('/api/profiles/?all=1')
    } else {
      return session.get('/api/profiles/')
    }
  },
  upsertProfile(firstName, lastName, speciality, inpe, profile) {
    const data = {
      first_name: firstName,
      last_name: lastName,
      speciality: speciality,
      inpe: inpe,
      user: profile.user,
    }
    if (profile.id) {
      return session.put('/api/profiles/' + profile.id + '/', data)
    } else {
      return session.post('/api/profiles/', data)
    }
  },
}
