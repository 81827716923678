import session from './session'

export default {
  getDentalProcedures(patientId) {
    return session.get('api/patients/' + patientId + '/dental/procedures/')
  },
  getDentalProcedure(idDentalProcedure) {
    return session.get('api/dental/procedures/' + idDentalProcedure + '/')
  },
  deleteDentalProcedure(dentalProcedure) {
    return session.delete(
      '/api/patients/' +
        dentalProcedure.patient.id +
        '/dental/procedures/' +
        dentalProcedure.id +
        '/',
    )
  },
  upsertDentalProcedure(dentalProcedure) {
    const data = {
      doctor: dentalProcedure.doctor,
      patient: dentalProcedure.patient,
      dental_procedure: dentalProcedure.dentalProcedure,
    }
    if (dentalProcedure.id) {
      return session.put(
        'api/patients/' +
          data.patient +
          '/dental/procedures/' +
          dentalProcedure.id +
          '/',
        data,
      )
    } else {
      return session.post('/api/dental/procedures/', data)
    }
  },
}
