import session from './session'

export default {
  getPatients(todayPatients = false) {
    if (todayPatients) {
      return session.get('/api/patients/?todayPatients=1')
    } else {
      return session.get('/api/patients/')
    }
  },
  getPatient(idPatient) {
    return session.get('api/patients/' + idPatient + '/')
  },
  upsertPatient(patient) {
    const data = {
      record_number: patient.recordNumber,
      doctor: patient.doctor,
      first_name: patient.firstName,
      last_name: patient.lastName,
      date_of_birth: patient.dateOfBirth,
      kids_number: patient.kidsNumber,
      family_status: patient.familyStatus,
      insurance: patient.insurance,
      is_virgin: patient.isVirgin,
      age: patient.age,
      address: patient.address,
      phone: patient.phone,
      job: patient.job,
      correspondent_doctors: patient.correspondentDoctors,
      medical_history: patient.medicalHistory,
      gynecological_history: patient.gynecologicalHistory,
      husband_age: patient.husbandAge,
      husband_job: patient.husbandJob,
      husband_medical_history: patient.husbandMedicalHistory,
      modified_by: patient.modifiedBy,
      modified_by_at: patient.modifiedByAt,
      today_patient: patient.todayPatient,
      today_patient_status: patient.todayPatientStatus,
    }
    if (patient.id) {
      return session.put('/api/patients/' + patient.id + '/', data)
    } else {
      return session.post('/api/patients/', data)
    }
  },
}
