import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DashLayout from '@/layouts/DashLayout'
import store from '../store'

const requireAuthenticated = (to, from, next) => {
  store.dispatch('auth/initialize').then(() => {
    if (!store.getters['auth/isAuthenticated']) {
      next('/client/login')
    } else {
      next()
    }
  })
}

const requireUnauthenticated = (to, from, next) => {
  store.dispatch('auth/initialize').then(() => {
    if (store.getters['auth/isAuthenticated']) {
      next('/dashboard/')
    } else {
      next()
    }
  })
}

const redirectLogout = (to, from, next) => {
  store.dispatch('auth/logout').then(() => next('/'))
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/dashboard',
    name: 'AV',
    component: DashLayout,
    redirect: '/dashboard/home',
    beforeEnter: requireAuthenticated,
    children: [
      {
        path: '/dashboard/home',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/dashboard/staff',
        name: 'Staff',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/DashboardStaff.vue'
          ),
      },
      {
        path: '/dashboard/profile',
        name: 'Profil',
        component: () => import('@/views/dashboard/Profile.vue'),
      },
      {
        path: '/dashboard/prescription',
        name: 'Prescription',
        component: () => import('@/views/dashboard/Prescription.vue'),
      },
      {
        path: '/dashboard/patient/:id/prescription',
        name: 'Prescription of Patient',
        component: () => import('@/views/dashboard/Prescription.vue'),
      },
      {
        path: '/dashboard/certificate',
        name: 'Certificate',
        component: () => import('@/views/dashboard/Certificate.vue'),
      },
      {
        path: '/dashboard/patient/:id/certificate',
        name: 'Certificate of Patient',
        component: () => import('@/views/dashboard/Certificate.vue'),
      },
      {
        path: '/dashboard/patient/record',
        name: 'Patient Record',
        component: () => import('@/views/dashboard/PatientRecord.vue'),
      },
      {
        path: '/dashboard/patient/:id/record',
        name: 'Patient Record with ID',
        component: () => import('@/views/dashboard/PatientRecord.vue'),
      },
      {
        path: '/dashboard/patients',
        name: 'Today Patients',
        component: () => import('@/views/Dashboard.vue'),
      },
    ],
  },
  {
    path: '/client',
    redirect: '/client/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
        beforeEnter: requireUnauthenticated,
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
        beforeEnter: requireUnauthenticated,
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        beforeEnter: requireUnauthenticated,
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
        beforeEnter: requireUnauthenticated,
      },
      {
        path: 'register/:key',
        name: 'Activate',
        component: () => import('@/views/pages/Activate'),
        beforeEnter: requireUnauthenticated,
      },
      {
        path: 'reset',
        name: 'Reset',
        component: () => import('@/views/pages/ResetPwd'),
        beforeEnter: requireUnauthenticated,
      },
      {
        path: 'reset/:uid/:token',
        name: 'Reset Confirm',
        component: () => import('@/views/pages/ResetPwdConfirm.vue'),
        beforeEnter: requireUnauthenticated,
      },
    ],
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: redirectLogout,
  },
  {
    path: '/charts',
    name: 'Charts',
    component: () => import('@/components/home/elements/Button.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
