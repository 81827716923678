import patientApi from '../api/patient'
import {
  PATIENT_FAILURE,
  PATIENT_SUCCESS,
  SET_PATIENT,
  SET_PATIENTS,
} from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    lastInsertedPatientId: null,
    patient: null,
    patients: [],
    error: false,
    msgError: null,
  },
  actions: {
    upsertPatient({ commit }, newPatient) {
      return patientApi
        .upsertPatient(newPatient)
        .then((response) => {
          const patientId = response.data.id
          commit(PATIENT_SUCCESS, patientId)
        })
        .catch(function (error) {
          console.log(error)
          commit(PATIENT_FAILURE, error.response.data)
        })
    },
    getPatient({ commit }, idPatient) {
      return patientApi
        .getPatient(idPatient)
        .then(({ data }) => {
          const patient = {}
          for (const key in data) {
            patient[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_PATIENT, patient)
        })
        .catch(function (error) {
          console.log(error)
          commit(PATIENT_FAILURE, error.response.data)
        })
    },
    getPatients({ commit }, todayPatients = false) {
      return patientApi
        .getPatients(todayPatients)
        .then(({ data }) => {
          const patients = data.map((item) => {
            const patient = {}
            for (const key in item) {
              patient[convertSnakeToCamelCase(key)] = item[key]
            }
            return patient
          })
          commit(SET_PATIENTS, patients)
        })
        .catch(function (error) {
          console.log(error)
          commit(PATIENT_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_PATIENTS](state, data) {
      state.patients = data
    },
    [SET_PATIENT](state, data) {
      state.patient = data
    },
    [PATIENT_SUCCESS](state, patientId) {
      state.error = false
      state.msgError = null
      state.lastInsertedPatientId = patientId
    },
    [PATIENT_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    patients: (state) => state.patients,
    patient: (state) => state.patient,
  },
}
