import session from './session'

export default {
  getPrescriptions(patientId) {
    const url =
      patientId !== null
        ? `/api/patients/${patientId}/prescriptions/`
        : '/api/prescriptions/'
    return session.get(url)
  },
  getPrescription(idPrescription) {
    return session.get('/api/prescriptions/' + idPrescription + '/')
  },
  upsertPrescription(prescription) {
    const data = {
      doctor: prescription.doctor,
      patient: prescription.patient,
      prescription: prescription.medicines,
    }
    if (prescription.id) {
      return session.put('/api/prescriptions/' + prescription.id + '/', data)
    } else {
      return session.post('/api/prescriptions/', data)
    }
  },
}
