import { createStore } from 'vuex'
import auth from './auth'
import signup from './signup'
import patient from './patient'
import prescription from './prescription'
import certificate from './certificate'
import radio from './radio'
import result from './result'
import child from './child'
import observation from './observation'
import constant from './constant'
import dentalProcedure from './dental'
import payment from './payment'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    auth,
    signup,
    patient,
    prescription,
    certificate,
    radio,
    result,
    child,
    observation,
    constant,
    dentalProcedure,
    payment,
  },
})
