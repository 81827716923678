import resultApi from '../api/result'
import {
  SET_RESULT,
  SET_RESULTS,
  RESULT_SUCCESS,
  RESULT_FAILURE,
} from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    result: null,
    results: [],
  },
  actions: {
    upsertResult({ commit }, newResult) {
      return resultApi
        .upsertResult(newResult)
        .then(({ data }) => {
          const result = {}
          for (const key in data) {
            result[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_RESULT, result)
        })
        .catch(function (error) {
          console.log(error)
          commit(RESULT_FAILURE, error.response.data)
        })
    },
    getResult({ commit }, idResult) {
      return resultApi
        .getResult(idResult)
        .then(({ data }) => {
          const result = {}
          for (const key in data) {
            result[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_RESULT, result)
        })
        .catch(function (error) {
          console.log(error)
          commit(RESULT_FAILURE, error.response.data)
        })
    },
    getResults({ commit }, { patientId }) {
      return resultApi
        .getResults(patientId)
        .then(({ data }) => {
          const results = data.map((item) => {
            const result = {}
            for (const key in item) {
              result[convertSnakeToCamelCase(key)] = item[key]
            }
            return result
          })
          commit(SET_RESULTS, results)
        })
        .catch(function (error) {
          console.log(error)
          commit(RESULT_FAILURE, error.response.data)
        })
    },
    deleteResult({ commit }, result) {
      return resultApi
        .deleteResult(result)
        .then(() => {
          commit(RESULT_SUCCESS)
        })
        .catch(function (error) {
          console.log(error)
          commit(RESULT_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_RESULTS](state, data) {
      state.results = data
    },
    [SET_RESULT](state, data) {
      state.result = data
    },
    [RESULT_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [RESULT_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    results: (state) => state.results,
    result: (state) => state.result,
  },
}
