<template>
  <component :is="layout" v-if="$route.name == 'Home'">
    <router-view v-model:layout="layout" />
  </component>
  <router-view v-if="$route.name != 'Home'" />
</template>

<style lang="scss">
// Import Home styles for this application
@import 'styles/home/style';

// Import Dashboard styles for this application
@import 'styles/style';
</style>

<script>
export default {
  name: 'App',
  data() {
    return {
      layout: 'div',
    }
  },
}
</script>
