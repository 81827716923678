export const LOGIN_BEGIN = 'LOGIN_BEGIN'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const REGISTRATION_BEGIN = 'REGISTRATION_BEGIN'
export const REGISTRATION_CLEAR = 'REGISTRATION_CLEAR'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const ACTIVATION_BEGIN = 'ACTIVATION_BEGIN'
export const ACTIVATION_CLEAR = 'ACTIVATION_CLEAR'
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const PASSWORD_RESET_BEGIN = 'PASSWORD_RESET_BEGIN'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'
export const PASSWORD_EMAIL_BEGIN = 'PASSWORD_EMAIL_BEGIN'
export const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS'
export const PASSWORD_EMAIL_FAILURE = 'PASSWORD_EMAIL_FAILURE'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_USER = 'SET_USER'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_PROFILES = 'SET_PROFILES'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAILURE = 'PROFILE_FAILURE'
export const SET_PATIENT = 'SET_PATIENT'
export const SET_PATIENTS = 'SET_PATIENTS'
export const PATIENT_SUCCESS = 'PATIENT_SUCCESS'
export const PATIENT_FAILURE = 'PATIENT_FAILURE'
export const SET_PRESCRIPTION = 'SET_PRESCRIPTION'
export const SET_PRESCRIPTIONS = 'SET_PRESCRIPTIONS'
export const PRESCRIPTION_SUCCESS = 'PRESCRIPTION_SUCCESS'
export const PRESCRIPTION_FAILURE = 'PRESCRIPTION_FAILURE'
export const SET_CERTIFICATE = 'SET_CERTIFICATE'
export const SET_CERTIFICATES = 'SET_CERTIFICATES'
export const CERTIFICATE_SUCCESS = 'CERTIFICATE_SUCCESS'
export const CERTIFICATE_FAILURE = 'CERTIFICATE_FAILURE'
export const SET_RADIO = 'SET_RADIO'
export const SET_RADIOS = 'SET_RADIOS'
export const RADIO_SUCCESS = 'RADIO_SUCCESS'
export const RADIO_FAILURE = 'RADIO_FAILURE'
export const SET_CHILD = 'SET_CHILD'
export const SET_CHILDS = 'SET_CHILDS'
export const CHILD_SUCCESS = 'CHILD_SUCCESS'
export const CHILD_FAILURE = 'CHILD_FAILURE'
export const SET_OBSERVATION = 'SET_OBSERVATION'
export const SET_OBSERVATIONS = 'SET_OBSERVATIONS'
export const OBSERVATION_SUCCESS = 'OBSERVATION_SUCCESS'
export const OBSERVATION_FAILURE = 'OBSERVATION_FAILURE'
export const SET_CONSTANT = 'SET_CONSTANT'
export const SET_CONSTANTS = 'SET_CONSTANTS'
export const CONSTANT_SUCCESS = 'CONSTANT_SUCCESS'
export const CONSTANT_FAILURE = 'CONSTANT_FAILURE'
export const SET_RESULT = 'SET_RESULT'
export const SET_RESULTS = 'SET_RESULTS'
export const RESULT_SUCCESS = 'RESULT_SUCCESS'
export const RESULT_FAILURE = 'RESULT_FAILURE'
export const SET_DENTAL_PROCEDURE = 'SET_DENTAL_PROCEDURE'
export const SET_DENTAL_PROCEDURES = 'SET_DENTAL_PROCEDURES'
export const DENTAL_PROCEDURE_SUCCESS = 'DENTAL_PROCEDURE_SUCCESS'
export const DENTAL_PROCEDURE_FAILURE = 'DENTAL_PROCEDURE_FAILURE'
export const SET_PAYMENT = 'SET_PAYMENT'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE'
