import session from './session'

export default {
  getObservations(patientId) {
    return session.get('api/patients/' + patientId + '/observations/')
  },
  getObservation(idObservation) {
    return session.get('api/observations/' + idObservation + '/')
  },
  deleteObservation(observation) {
    return session.delete(
      '/api/patients/' +
        observation.patient.id +
        '/observations/' +
        observation.id +
        '/',
    )
  },
  upsertObservation(observation) {
    const data = {
      doctor: observation.doctor,
      patient: observation.patient,
      observation: observation.observation,
    }
    if (observation.id) {
      return session.put(
        'api/patients/' +
          data.patient +
          '/observations/' +
          observation.id +
          '/',
        data,
      )
    } else {
      return session.post('/api/observations/', data)
    }
  },
}
