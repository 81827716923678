<template>
  <CFooter>
    <div v-if="payment">
      <div v-if="!payment.isTrial">
        <span class="ms-1" :style="getStyleObject(payment.planExpiresAt)"
          >Plan <b>{{ payment.plan }}</b> valable jusqu'au
          <b>{{ formattedDate(payment.planExpiresAt) }}</b></span
        >
      </div>
      <div v-if="payment.isTrial">
        <span class="ms-1" :style="getStyleObject(payment.trialExpiresAt)"
          >Essai gratuit valable jusqu'au
          <b>{{ formattedDate(payment.trialExpiresAt) }}</b></span
        >
      </div>
      <!-- <div class="ms-auto">
      <span class="me-1" target="_blank">Réalisé par</span>
      <a href="https://nextdevninja.com" target="_blank">NextDev</a>
    </div> -->
    </div>
  </CFooter>
</template>

<script>
import { mapGetters } from 'vuex'
import format from 'date-fns/format'
export default {
  name: 'AppFooter',
  computed: {
    ...mapGetters('payment', ['payment']),
  },
  methods: {
    getPayment() {
      this.$store.dispatch('payment/getPayment').catch((error) => {
        console.error('Error fetching payment:', error)
      })
    },
    formattedDate(date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    getStyleObject(date) {
      const planExpirationDate = new Date(date)
      const currentDate = new Date()
      const differenceInDays = Math.abs(
        (planExpirationDate - currentDate) / (24 * 60 * 60 * 1000),
      )
      if (differenceInDays > 7) {
        return {
          color: '#618c7f', // green
        }
      } else {
        return {
          color: '#e55353', // red
        }
      }
    },
  },
  mounted() {
    this.getPayment()
  },
}
</script>
