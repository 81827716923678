import session from './session'

export default {
  getCertificates(patientId) {
    const url =
      patientId !== null
        ? `/api/patients/${patientId}/certificates/`
        : '/api/certificates/'
    return session.get(url)
  },
  getCertificate(idCertificate) {
    return session.get('api/certificates/' + idCertificate + '/')
  },
  upsertCertificate(certificate) {
    const data = {
      doctor: certificate.doctor,
      patient: certificate.patient,
      number_of_days_off: certificate.numberOfDaysOff,
      issued_date: certificate.issuedDate,
      start_date: certificate.startDate,
      end_date: certificate.endDate,
      created_at: certificate.createdAt,
    }
    if (certificate.id) {
      return session.put('/api/certificates/' + certificate.id + '/', data)
    } else {
      return session.post('/api/certificates/', data)
    }
  },
}
