import prescriptionApi from '../api/prescription'
import {
  SET_PRESCRIPTION,
  SET_PRESCRIPTIONS,
  PRESCRIPTION_SUCCESS,
  PRESCRIPTION_FAILURE,
} from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    prescription: null,
    prescriptions: [],
  },
  actions: {
    upsertPrescription({ commit }, newPrescription) {
      return prescriptionApi
        .upsertPrescription(newPrescription)
        .then(() => {
          commit(PRESCRIPTION_SUCCESS)
        })
        .catch(function (error) {
          console.log(error)
          commit(PRESCRIPTION_FAILURE, error.response.data)
        })
    },
    getPrescription({ commit }, idPrescription) {
      return prescriptionApi
        .getPrescription(idPrescription)
        .then(({ data }) => {
          const prescription = {}
          for (const key in data) {
            prescription[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_PRESCRIPTION, prescription)
        })
        .catch(function (error) {
          console.log(error)
          commit(PRESCRIPTION_FAILURE, error.response.data)
        })
    },
    getPrescriptions({ commit }, { patientId = null } = {}) {
      return prescriptionApi
        .getPrescriptions(patientId)
        .then(({ data }) => {
          const prescriptions = data.map((item) => {
            const prescription = {}
            for (const key in item) {
              prescription[convertSnakeToCamelCase(key)] = item[key]
            }
            return prescription
          })
          commit(SET_PRESCRIPTIONS, prescriptions)
        })
        .catch(function (error) {
          console.log(error)
          commit(PRESCRIPTION_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_PRESCRIPTIONS](state, data) {
      state.prescriptions = data
    },
    [SET_PRESCRIPTION](state, data) {
      state.prescription = data
    },
    [PRESCRIPTION_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [PRESCRIPTION_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    prescriptions: (state) => state.prescriptions,
    prescription: (state) => state.prescription,
  },
}
