import session from './session'

export default {
  getConstants(patientId) {
    return session.get('api/patients/' + patientId + '/constants/')
  },
  getConstant(idConstant) {
    return session.get('api/constants/' + idConstant + '/')
  },
  deleteConstant(constant) {
    return session.delete(
      '/api/patients/' +
        constant.patient.id +
        '/constants/' +
        constant.id +
        '/',
    )
  },
  upsertConstant(constant) {
    const data = {
      doctor: constant.doctor,
      patient: constant.patient,
      constant: constant.constant,
    }
    if (constant.id) {
      return session.put(
        'api/patients/' + data.patient + '/constants/' + constant.id + '/',
        data,
      )
    } else {
      return session.post('/api/constants/', data)
    }
  },
}
