import dentalApi from '../api/dental'
import {
  SET_DENTAL_PROCEDURE,
  SET_DENTAL_PROCEDURES,
  DENTAL_PROCEDURE_SUCCESS,
  DENTAL_PROCEDURE_FAILURE,
} from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    dentalProcedure: null,
    dentalProcedures: [],
  },
  actions: {
    upsertDentalProcedure({ commit }, newDentalProcedure) {
      return dentalApi
        .upsertDentalProcedure(newDentalProcedure)
        .then(({ data }) => {
          const dentalProcedure = {}
          for (const key in data) {
            dentalProcedure[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_DENTAL_PROCEDURE, dentalProcedure)
        })
        .catch(function (error) {
          console.log(error)
          commit(DENTAL_PROCEDURE_FAILURE, error.response.data)
        })
    },
    getDentalProcedure({ commit }, idDentalProcedure) {
      return dentalApi
        .getDentalProcedure(idDentalProcedure)
        .then(({ data }) => {
          const dentalProcedure = {}
          for (const key in data) {
            dentalProcedure[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_DENTAL_PROCEDURES, dentalProcedure)
        })
        .catch(function (error) {
          console.log(error)
          commit(DENTAL_PROCEDURE_FAILURE, error.response.data)
        })
    },
    getDentalProcedures({ commit }, { patientId }) {
      return dentalApi
        .getDentalProcedures(patientId)
        .then(({ data }) => {
          const dentalProcedures = data.map((item) => {
            const dentalProcedure = {}
            for (const key in item) {
              dentalProcedure[convertSnakeToCamelCase(key)] = item[key]
            }
            return dentalProcedure
          })
          commit(SET_DENTAL_PROCEDURES, dentalProcedures)
        })
        .catch(function (error) {
          console.log(error)
          commit(DENTAL_PROCEDURE_FAILURE, error.response.data)
        })
    },
    deleteDentalProcedure({ commit }, dentalProcedure) {
      return dentalApi
        .deleteDentalProcedure(dentalProcedure)
        .then(() => {
          commit(DENTAL_PROCEDURE_SUCCESS)
        })
        .catch(function (error) {
          console.log(error)
          commit(DENTAL_PROCEDURE_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_DENTAL_PROCEDURES](state, data) {
      state.dentalProcedures = data
    },
    [SET_DENTAL_PROCEDURE](state, data) {
      state.dentalProcedure = data
    },
    [DENTAL_PROCEDURE_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [DENTAL_PROCEDURE_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    dentalProcedures: (state) => state.dentalProcedures,
    dentalProcedure: (state) => state.dentalProcedure,
  },
}
