import session from './session'

export default {
  getResults(patientId) {
    return session.get('api/patients/' + patientId + '/results/')
  },
  getResult(idResult) {
    return session.get('api/results/' + idResult + '/')
  },
  deleteResult(result) {
    return session.delete(
      '/api/patients/' + result.patient.id + '/results/' + result.id + '/',
    )
  },
  upsertResult(result) {
    const data = {
      doctor: result.doctor,
      patient: result.patient,
      type: result.type,
      result: result.result,
    }
    if (result.id) {
      return session.put(
        'api/patients/' + data.patient + '/results/' + result.id + '/',
        data,
      )
    } else {
      return session.post('/api/results/', data)
    }
  },
}
