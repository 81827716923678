import session from './session'

export default {
  getChilds(patientId) {
    return session.get('api/patients/' + patientId + '/childs/')
  },
  getChild(idchild) {
    return session.get('api/childs/' + idchild + '/')
  },
  deleteChild(child) {
    return session.delete(
      '/api/patients/' + child.patient.id + '/childs/' + child.id + '/',
    )
  },
  upsertChild(child) {
    const data = {
      doctor: child.doctor,
      patient: child.patient,
      child: child.child,
    }
    if (child.id) {
      return session.put(
        'api/patients/' + data.patient + '/childs/' + child.id + '/',
        data,
      )
    } else {
      return session.post('/api/childs/', data)
    }
  },
}
