import auth from '../api/auth'
import session from '../api/session'
import {
  LOGIN_BEGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  PASSWORD_EMAIL_BEGIN,
  PASSWORD_EMAIL_SUCCESS,
  PASSWORD_EMAIL_FAILURE,
  PASSWORD_RESET_BEGIN,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  SET_PROFILE,
  SET_PROFILES,
  PROFILE_FAILURE,
  PROFILE_SUCCESS,
  REMOVE_TOKEN,
  SET_TOKEN,
  SET_USER,
  USER_FAILURE,
} from './types'

const TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY'
const isProduction = process.env.NODE_ENV === 'production'

const initialState = {
  authenticating: false,
  resettingPassword: false,
  sendingResetEmail: false,
  error: false,
  token: null,
  msgError: null,
  user: {
    id: 0,
    username: '',
  },
  profile: {
    id: null,
    firstName: '',
    lastName: '',
    speciality: '',
    inpe: null,
    user: null,
  },
  profiles: [],
}

const getters = {
  isAuthenticated: (state) => !!state.token,
}

const actions = {
  login({ commit }, { username, password }) {
    commit(LOGIN_BEGIN)
    return auth
      .login(username, password)
      .then(({ data }) => commit(SET_TOKEN, data.key))
      .then(() => commit(LOGIN_SUCCESS))
      .catch(function (error) {
        commit(LOGIN_FAILURE, error.response.data)
      })
  },
  logout({ commit }) {
    return auth
      .logout()
      .then(() => commit(LOGOUT))
      .finally(() => commit(REMOVE_TOKEN))
  },
  sendPasswordResetEmail({ commit }, { email }) {
    commit(PASSWORD_EMAIL_BEGIN)
    return auth
      .sendPasswordResetEmail(email)
      .then(() => commit(PASSWORD_EMAIL_SUCCESS))
      .catch(function (error) {
        commit(PASSWORD_EMAIL_FAILURE, error.response.data)
      })
  },
  resetPassword({ commit }, { uid, token, password1, password2 }) {
    commit(PASSWORD_RESET_BEGIN)
    return auth
      .resetPassword(uid, token, password1, password2)
      .then(() => commit(PASSWORD_RESET_SUCCESS))
      .catch(function (error) {
        console.log(error)
        commit(PASSWORD_RESET_FAILURE, error.response.data)
      })
  },
  initialize({ commit }) {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY)

    if (isProduction && token) {
      commit(REMOVE_TOKEN)
    }

    if (!isProduction && token) {
      commit(SET_TOKEN, token)
    }
  },
  upsertProfile(
    { commit },
    { firstName, lastName, speciality, inpe, profile },
  ) {
    return auth
      .upsertProfile(firstName, lastName, speciality, inpe, profile)
      .then(() => commit(PROFILE_SUCCESS))
      .catch(function (error) {
        console.log(error)
        commit(PROFILE_FAILURE, error.response.data)
      })
  },
  getUser({ commit }) {
    return auth
      .getUser()
      .then(({ data }) => {
        commit(SET_USER, data)
      })
      .catch(function (error) {
        console.log(error)
        commit(USER_FAILURE, error.response.data)
      })
  },
  getProfile({ commit }, all = false) {
    return auth
      .getProfile(all)
      .then(({ data }) => {
        if (all) {
          commit(SET_PROFILES, data)
        } else {
          commit(SET_PROFILE, data[0])
        }
      })
      .catch(function (error) {
        console.log(error)
        commit(PROFILE_FAILURE, error.response.data)
      })
  },
}

const mutations = {
  [LOGIN_BEGIN](state) {
    state.authenticating = true
    state.error = false
  },
  [LOGIN_FAILURE](state, error) {
    state.authenticating = false
    state.error = true
    state.msgError = error
  },
  [LOGIN_SUCCESS](state) {
    state.authenticating = false
    state.error = false
  },
  [LOGOUT](state) {
    state.authenticating = false
    state.error = false
  },
  [PASSWORD_EMAIL_BEGIN](state) {
    state.sendingResetEmail = true
    state.error = false
  },
  [PASSWORD_EMAIL_FAILURE](state, error) {
    state.sendingResetEmail = false
    state.error = true
    state.msgError = error
  },
  [PASSWORD_EMAIL_SUCCESS](state) {
    state.sendingResetEmail = false
    state.error = false
  },
  [PASSWORD_RESET_BEGIN](state) {
    state.resettingPassword = true
    state.error = false
  },
  [PASSWORD_RESET_FAILURE](state, error) {
    state.resettingPassword = false
    state.error = true
    state.msgError = error
  },
  [PASSWORD_RESET_SUCCESS](state) {
    state.resettingPassword = false
    state.error = false
  },
  [SET_TOKEN](state, token) {
    if (!isProduction) localStorage.setItem(TOKEN_STORAGE_KEY, token)
    session.defaults.headers.Authorization = `Token ${token}`
    state.token = token
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    delete session.defaults.headers.Authorization
    state.token = null
  },
  [SET_USER](state, data) {
    state.user.id = data.pk
    state.user.username = data.username
  },
  [USER_FAILURE](state, error) {
    state.error = true
    state.msgError = error
  },
  [SET_PROFILE](state, data) {
    state.profile = data
  },
  [SET_PROFILES](state, data) {
    state.profiles = data
  },
  [PROFILE_SUCCESS](state) {
    state.error = false
    state.msgError = null
  },
  [PROFILE_FAILURE](state, error) {
    state.error = true
    state.msgError = error
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
