import observationApi from '../api/observation'
import {
  SET_OBSERVATION,
  SET_OBSERVATIONS,
  OBSERVATION_SUCCESS,
  OBSERVATION_FAILURE,
} from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    observation: null,
    observations: [],
  },
  actions: {
    upsertObservation({ commit }, newObservation) {
      return observationApi
        .upsertObservation(newObservation)
        .then(({ data }) => {
          const observation = {}
          for (const key in data) {
            observation[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_OBSERVATION, observation)
        })
        .catch(function (error) {
          console.log(error)
          commit(OBSERVATION_FAILURE, error.response.data)
        })
    },
    getObservation({ commit }, idObservation) {
      return observationApi
        .getObservation(idObservation)
        .then(({ data }) => {
          const observation = {}
          for (const key in data) {
            observation[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_OBSERVATION, observation)
        })
        .catch(function (error) {
          console.log(error)
          commit(OBSERVATION_FAILURE, error.response.data)
        })
    },
    getObservations({ commit }, { patientId }) {
      return observationApi
        .getObservations(patientId)
        .then(({ data }) => {
          const observations = data.map((item) => {
            const observation = {}
            for (const key in item) {
              observation[convertSnakeToCamelCase(key)] = item[key]
            }
            return observation
          })
          commit(SET_OBSERVATIONS, observations)
        })
        .catch(function (error) {
          console.log(error)
          commit(OBSERVATION_FAILURE, error.response.data)
        })
    },
    deleteObservation({ commit }, observation) {
      return observationApi
        .deleteObservation(observation)
        .then(() => {
          commit(OBSERVATION_SUCCESS)
        })
        .catch(function (error) {
          console.log(error)
          commit(OBSERVATION_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_OBSERVATIONS](state, data) {
      state.observations = data
    },
    [SET_OBSERVATION](state, data) {
      state.observation = data
    },
    [OBSERVATION_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [OBSERVATION_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    observations: (state) => state.observations,
    observation: (state) => state.observation,
  },
}
