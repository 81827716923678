import certificateApi from '../api/certificate'
import {
  SET_CERTIFICATE,
  SET_CERTIFICATES,
  CERTIFICATE_SUCCESS,
  CERTIFICATE_FAILURE,
} from './types'

function convertSnakeToCamelCase(input) {
  return input
    .split('_')
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export default {
  namespaced: true,
  state: {
    error: false,
    msgError: null,
    certificate: null,
    certificates: [],
  },
  actions: {
    upsertCertificate({ commit }, newCertificate) {
      return certificateApi
        .upsertCertificate(newCertificate)
        .then(() => {
          commit(CERTIFICATE_SUCCESS)
        })
        .catch(function (error) {
          console.log(error)
          commit(CERTIFICATE_FAILURE, error.response.data)
        })
    },
    getCertificate({ commit }, idCertificate) {
      return certificateApi
        .getCertificate(idCertificate)
        .then(({ data }) => {
          const certificate = {}
          for (const key in data) {
            certificate[convertSnakeToCamelCase(key)] =
              data[key] === null ? '' : data[key]
          }
          commit(SET_CERTIFICATE, certificate)
        })
        .catch(function (error) {
          console.log(error)
          commit(CERTIFICATE_FAILURE, error.response.data)
        })
    },
    getCertificates({ commit }, { patientId = null } = {}) {
      return certificateApi
        .getCertificates(patientId)
        .then(({ data }) => {
          const certificates = data.map((item) => {
            const certificate = {}
            for (const key in item) {
              certificate[convertSnakeToCamelCase(key)] = item[key]
            }
            return certificate
          })
          commit(SET_CERTIFICATES, certificates)
        })
        .catch(function (error) {
          console.log(error)
          commit(CERTIFICATE_FAILURE, error.response.data)
        })
    },
  },
  mutations: {
    [SET_CERTIFICATES](state, data) {
      state.certificates = data
    },
    [SET_CERTIFICATE](state, data) {
      state.certificate = data
    },
    [CERTIFICATE_SUCCESS](state) {
      state.error = false
      state.msgError = null
    },
    [CERTIFICATE_FAILURE](state, error) {
      state.error = true
      state.msgError = error
    },
  },
  getters: {
    certificates: (state) => state.certificates,
    certificate: (state) => state.certificate,
  },
}
